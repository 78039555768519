import { link } from "fs";

interface IRoute {
  name: string;
  link: string;
}

export const displayNameMapping: { [key: string]: IRoute } = {
  // '': 'Sign In',
  opd: {
    name: "OPD",
    link: "opd",
  },
  "diagnosis-master":{
    name:"Diagnosis Masters",
       link:"/diagnosis-master?page=1&q=",
  },
  "diagnosis-type":{
    name:"Diagnosis Types",
    link:"/diagnosis-type?page=1&q=",
  },

  Diagnosis :{
    name:"Diagnosis",
    link:"/diagnosis?page=1&q=",
  }, 
  medicine: {
    name: "Medicine",
    link: "/medicine?page=1&q=",
  },
  "medicine-dose": {
    name: "Medicine Dose",
    link: "/medicine-dose?page=1&q=",
  },
 
  "medicine-masters":{
    name:"Medicine Masters",
    link:"/medicine-masters?page=1&q=",
  },
  "instruction":{
    name:"Medicine Instructions",
    link:"/instruction?type=medicine&page=1&q=",
  }, 
  "medicine-group":{
    name:"Medicine Groups",
    link:"/medicine-group?page=1&q=",
  },
  "medicine-Generic-name":{
    name:"Medicine Generic Names",
    link:"/medicine-Generic-name?page=1&q=",
  },
  "medicine-company":{
    name:"Medicine Companies",
    link:"/medicine-company?page=1&q=",
  },
  "medicine-formulation":{
    name:"Medicine Formulations",
    link:"/medicine-formulation?page=1&q=",
  },
  "medicine-used-as":{
    name:"Medicine Used As",
    link:"/medicine-used-as?page=1&q=",
  },
  "medicine-diluent-name":{
    name:"Medicine Diluent Names",
    link:"/medicine-diluent-name?page=1&q=",
  },
  "medicine-route-of-administration":{
    name:"Medicine Route of Administration",
    link:"/medicine-route-of-administration?page=1&q=",
  },
  "medicine-relation-to-food":{
    name:"Medicine Relation to Food",
    link:"/medicine-relation-to-food?page=1&q=",
  },
  "medicine-indication-for-sos-medicine":{
    name:"Medicine Indication for SOS medicine",
    link:"/medicine-indication-for-sos-medicine?page=1&q=",
  },
 

  "Follow-ups":{
    name:"Follow-ups",
    link:"/Follow-ups?page=1&q=",
  },
  "followup-reason":{
    name:"Follow-up Reasons",
    link:"/followup-reason?page=1&q=",
  },
  "instruction?type=follow-up":{
    name:"Follow-up Instructions",
    link:"/instruction?type=follow-up&page=1&q=",
  },

  // "investigation-maste":{
  //   name:"Investigation Masters",
  //   link:"/investigation-maste",
  // },
  // "investigation-sample-collected-from-list":{
  //   name:"Sample Collected From",
  //   link:"/investigation-sample-collected-from-list",
  // },

  "category":{
    name:"Categories",
    link:"/category?page=1",
  },

  "doctor":{
    name:"Consulting Doctors",
    link:"/doctor?tab=consulting-doctor&page=1&q=",
  },

  "symptoms":{
    name:"Symptoms",
    link:"/symptoms?page=1&q=",
  },
 
  "quick-notes":{
    name:"Chief Complaint Notes",
    link:"/quick-notes?type=chief-complaint&page=1&q=",
  },
  


  
  // 'forgot-password': 'Forgot Password',
  // 'reset-password': 'Reset Password',
  // 'dashboard': 'Dashboard',
  // 'consumer': 'Consumer',
  // 'consumer-save': 'Consumer Save',
  // 'Add ': 'Add Appointments',
  // 'patient-save': 'Add Appointments Save',
  // 'appointments': 'Reception Appointments',
  // 'opd-clinical': 'OPD',
  // 'certificates': 'Certificates',
  // 'certificates-save': 'Certificates Save',
  // 'patient-master': 'Patients',
  // 'patient-master-save': 'Patient Save',
  // 'patient-history': 'Patient History',
  // 'staff': 'Staff',
  // 'staff-save': 'Staff Save',
  // 'users': 'Users',
  // 'users-save': 'Users Save',
  // 'lead': 'Lead',
  // 'lead-save': 'Lead Save',
  // 'distribute': 'Distribute',
  // 'distribute-save': 'Distribute Save',
  // 'opd-billing': 'Opd Billing',
  // 'opd-billing-save': 'Opd Billing Save',
  // 'master': 'Master',
  // 'medicine-template': 'Medicine Template',
  // 'medicine-template-save': 'Medicine Template Save',
  // 'medicine-master': 'Medicine Master',
  // 'medicine-master-save': 'Medicine Master Save',
  // 'organization': 'Organization',
  // 'organization-save': 'Organization Save',
  // 'branch': 'Branch',
  // 'branch-save': 'Branch Save',
  // 'department': 'Department',
  // 'department-save': 'Department Save',
  // 'speciality': 'Speciality',
  // 'speciality-save': 'Speciality Save',
  // 'settings': 'Settings',
  // 'doctor-save': 'Doctor Save',
  // 'doctor': 'Doctor',
  // 'medical-representative': 'Medical Representative',
  // 'medical-representative-save': 'Medical Representative Save',
  // 'diagnosis-type': 'Diagnosis Type',
  // 'diagnosis-type-save': 'Diagnosis Type Save',
  // 'icd-codes': 'ICD Codes',
  // 'icd-codes-save': 'ICD Codes Save',
  // 'diagnosis-master': 'Diagnosis Master',
  // 'diagnosis-master-save': 'Diagnosis Master Save',
  // 'investigation-master': 'Investigation Master',
  // 'investigation-master-save': 'Investigation Master Save',
  // 'followup-master': 'Follow Up Master',
  // 'followup-master-save': 'Follow Up Master Save',
  // 'instruction-master': 'Instruction Master',
  // 'instruction-master-save': 'Instruction Master Save',
  // 'followup-duration': 'Follow Up Duration',
  // 'followup-duration-save': 'Follow Up Duration Save',
  // 'followup-reason': 'Follow Up Reason',
  // 'followup-reason-save': 'Follow Up Reason Save',
  // 'followup-instruction': 'Follow Up Instruction',
  // 'followup-instruction-save': 'Follow Up Instruction Save',
  // 'diagnosis-template': 'Diagnosis Template',
  // 'diagnosis-template-save': 'Diagnosis Template Save',
  // 'investigation-template': 'Investigation Template',
  // 'investigation-template-save': 'Investigation Template Save',
  // 'certificates-template-save': 'Certificates Template Save',
  // 'certificates-template': 'Certificates Template',
  // 'procedure-template-save': 'Procedure/Operation',
  // 'procedure-template': 'Procedure Template',
  // 'diet-and-nutrition-instruction': 'Diet and Nutrition Instruction',
  // 'diet-and-nutrition-instruction-save': 'Diet and Nutrition Instruction Save',
  // 'investigation-instruction': 'Investigation Instruction',
  // 'investigation-instruction-save': 'Investigation Instruction Save',
  // 'medicine-instruction': 'Medicine Instruction',
  // 'medicine-instruction-save': 'Medicine Instruction Save',
  // 'special-instruction': 'Special Instruction',
  // 'special-instruction-save': 'Special Instruction Save',
  // 'alert-instruction': 'Alert Instruction',
  // 'alert-instruction-save': 'Alert Instruction Save',
  // 'general-instruction': 'General Instruction',
  // 'general-instruction-save': 'General Instruction Save',
  // 'laboratory': 'Laboratory',
  // 'laboratory-save': 'Laboratory Save',
  // 'hospital': 'Hospital',
  // 'hospital-save': 'Hospital Save',
  // 'add-appointment': 'Appointments',
  // 'symptoms': 'Symptoms',
  // 'symptoms-save': 'Symptoms Save',
  // 'profile': 'Profile',
  // 'doctor-schedule': 'Doctor Schedule',
  // 'outreach-visits': 'Outreach Visits',
  // 'report': 'Report',
  // 'billing-item-master': 'Billing',
  // 'billing-item-master-save': 'Billing  Save',
  // 'Tax': 'Tax',
  // 'TaxSave': 'Tax Save',
  // 'medicine-Generic-name': 'Medicine Generic Name',
  // 'medicine-Generic-name-save': 'Medicine Generic Name Save',
  // 'medicine-group': 'Medicine Group',
  // 'medicine-group-save': 'Medicine Group Save',
  // 'medicine-company': 'Medicine Company',
  // 'medicine-company-save': 'Medicine Company Save',
  // 'medicine-formulation': 'Medicine Formulation',
  // 'medicine-formulation-save': 'Medicine Formulation Save',
  // 'medicine-used-as': 'Medicine Used As',
  // 'medicine-used-as-save': 'Medicine Used As Save',
  // 'medicine-diluent-name': 'Medicine Diluent Name',
  // 'medicine-diluent-name-save': 'Medicine Used As Save',
  // 'item-save': 'Item Save',
};
