import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { displayNameMapping } from "./BreadcrumbHelper";
import { Box, Typography } from "@mui/material";

const CustomBreadcrumbs = () => {
  const location = useLocation();
  const { pathname } = location;
  const segments = pathname.split("/").filter(Boolean);
  let url = "";
  const breadcrumbLink = segments.map((segment, index: number) => {
    let displayName = "";

    let displayName2 = segment?.split("-")?.join(" ");

    displayName = displayNameMapping[segment]?.name || displayName2;

    url += displayNameMapping[segment]?.link || `/${segment}`;

    const isLast = segments.length - 1;

    if (index === isLast) {
      return (
        <Typography
          key={index}
          style={{
            fontSize: "14px",
            textDecoration: "none",
            fontWeight: "500",
            color: "black",
          }}>
          <span style={{ textTransform: "capitalize" }}>{displayName}</span>
        </Typography>
      );
    } else {
      return (
        <Link
          key={index}
          to={url}
          style={{
            fontSize: "14px",
            textDecoration: "none",
            fontWeight: "500",
            color: "#3b75ff",
          }}>
          <span style={{ textTransform: "capitalize" }}>
            {displayName} {" / "}
          </span>
        </Link>
      );
    }
  });

  return (
    <Box
      sx={{
        display: "flex",
        gap: 0.5,
        flexWrap: "wrap",
      }}>
      {breadcrumbLink}
    </Box>
  );
};

export default CustomBreadcrumbs;
